import styled from '@emotion/styled';
import { forwardRef, Fragment } from 'react';

import { BannerBadge } from '@/models/backendsApi/v2/Views/ViewsType';

import * as styles from './TopCarouselContent.styles';

interface TopCarouselContentIndicatorProps {
  className?: string;
  index: number;
  length: number;
}

export const TopCarouselContentIndicator = forwardRef<HTMLSpanElement, TopCarouselContentIndicatorProps>(
  ({ className, index, length }, ref): ReactJSX.Element => (
    <div css={styles.indicatorStyle} className={className}>
      <span css={styles.indicatorActiveStyle} ref={ref}>
        {index + 1}
      </span>
      <i css={styles.indicatorDividerStyle} />
      <span css={styles.indicatorInactiveStyle}>{length}</span>
    </div>
  ),
);

interface TopCarouselContentBadgeProps {
  className?: string;
  badges: BannerBadge[];
}

export const TopCarouselContentBadge = ({ className, badges }: TopCarouselContentBadgeProps): ReactJSX.Element => (
  <>
    {badges.length > 0 && (
      <div css={styles.badgesStyle} className={className}>
        {badges.map(badge => (
          <img css={styles.badgeStyle} src={badge.badge_url} alt={badge.badge_text} key={badge.badge_type} />
        ))}
      </div>
    )}
  </>
);

export const TopCarouselContentTitle = styled.span(styles.titleStyle);
export const TopCarouselContentDescription = styled.span(styles.descriptionStyle);

interface TopCarouselContentCopyrightsProps {
  className?: string;
  copyrights: string[];
}

export const TopCarouselContentCopyrights = ({
  className,
  copyrights,
}: TopCarouselContentCopyrightsProps): ReactJSX.Element => {
  const copyrightItems = copyrights.map((copyright, index) => ({ copyright, key: index }));
  if (!copyrights.length) {
    return <></>;
  }

  return (
    <div css={styles.copyrightsStyle} className={className}>
      {copyrightItems.map(copyright => (
        <Fragment key={copyright.key}>
          {copyright.copyright}
          <br />
        </Fragment>
      ))}
    </div>
  );
};

export const TopCarouselContent = styled.div(styles.contentStyle);
