import { css } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';

import * as sectionStyles from '../index.styles';

export const ITEM_MARGIN = 6;
export const UNEXPANDED_ITEM_WIDTH = 40 + ITEM_MARGIN;
export const UNEXPANDED_ITEM_COUNT = 2;
export const UNEXPANDED_BOX = UNEXPANDED_ITEM_WIDTH * UNEXPANDED_ITEM_COUNT;

export const carouselWrapperStyle = css`
  ${sectionStyles.sectionStyle};
  ${sectionStyles.sectionMarginStyle};
`;

export const carouselStyle = css`
  position: relative;
  margin: 0 ${-ITEM_MARGIN / 2}px;
  max-width: ${1110 + ITEM_MARGIN}px;
  height: 400px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-right: -${UNEXPANDED_BOX}px;
      height: 343px;
    `,
  )};
`;

export const carouselChildrenStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  margin: 0 ${ITEM_MARGIN / 2}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      right: ${UNEXPANDED_BOX - ITEM_MARGIN / 2}px;
    `,
  )};
`;

export const carouselItemsStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
