import { useMemo, useRef } from 'react';

import { ArrowButton } from '@/components/common/ArrowButton';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { throttle } from '@/utils/functions';

import * as styles from './TopCarouselArrow.styles';

interface TopCarouselArrowProps {
  onClickPrev: () => void;
  onClickNext: () => void;
}

export const TopCarouselArrow = ({ onClickPrev, onClickNext }: TopCarouselArrowProps): ReactJSX.Element => {
  // throttle 함수가 새로 생성되어서는 안되기에,
  // onClick*의 deps에서 콜백을 빼기 위해 state를 ref로 변환해 사용
  const onClickPrevRef = useRef(onClickPrev);
  const onClickPrevThrottled = useMemo(() => throttle(() => onClickPrevRef.current(), 200, true), []);
  onClickPrevRef.current = onClickPrev;

  const onClickNextRef = useRef(onClickNext);
  const onClickNextThrottled = useMemo(() => throttle(() => onClickNextRef.current(), 200, true), []);
  onClickNextRef.current = onClickNext;

  const sectionTrackingData = useSectionTrackingDataContext();
  return (
    <>
      <TrackClickEvent
        screenName={sectionTrackingData.screenName}
        target="scroll_left"
        params={sectionTrackingData.params}>
        <ArrowButton css={styles.prevButtonStyle} direction="left" label="이전" onClick={onClickPrevThrottled} />
      </TrackClickEvent>
      <TrackClickEvent
        screenName={sectionTrackingData.screenName}
        target="scroll_right"
        params={sectionTrackingData.params}>
        <ArrowButton css={styles.nextButtonStyle} direction="right" label="다음" onClick={onClickNextThrottled} />
      </TrackClickEvent>
    </>
  );
};
