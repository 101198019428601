import { memo, MouseEvent, ReactNode, Ref } from 'react';

import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { TopCarouselBackground } from '@/components/genreHome/common/TopCarousel/TopCarouselBackground';
import {
  TopCarouselContent,
  TopCarouselContentBadge,
  TopCarouselContentCopyrights,
  TopCarouselContentDescription,
  TopCarouselContentIndicator,
  TopCarouselContentTitle,
} from '@/components/genreHome/common/TopCarousel/TopCarouselContent';

import type { TopCarouselCoverRenderItem } from './TopCarouselCover';
import * as styles from './TopCarouselCoverItem.styles';

interface TopCarouselCoverItemWrapperProps {
  item: TopCarouselCoverRenderItem;
  itemRef: Ref<HTMLAnchorElement>;
  onItemClick: (event: MouseEvent) => void;
}

const TopCarouselCoverItemWrapper = memo(
  ({
    item,
    itemRef,
    screenName = 'genre_home',
    onItemClick,
    children,
  }: TopCarouselCoverItemWrapperProps & { children: ReactNode; screenName?: string }) => (
    <TrackClickEvent screenName={screenName} target="top_carousel_cover" params={item.params}>
      <a
        css={styles.carouselItemStyle}
        href={item.contents.landing_url}
        ref={itemRef}
        tabIndex={item.initialFlags.isExpanded ? 0 : -1}
        onClick={onItemClick}
        data-hydrate-expanded={item.initialFlags.isExpanded}
        data-hydrate-hidden={item.initialFlags.isHidden}>
        {children}
      </a>
    </TrackClickEvent>
  ),
);

export const TopCarouselCoverItemSkeleton = memo(({ item, itemRef, onItemClick }: TopCarouselCoverItemWrapperProps) => (
  <TopCarouselCoverItemWrapper item={item} itemRef={itemRef} onItemClick={onItemClick}>
    <div css={[styles.carouselItemInnerSkeletonStyle, styles.carouselItemInnerStyle]} />
  </TopCarouselCoverItemWrapper>
));

interface TopCarouselCoverItemProps extends TopCarouselCoverItemWrapperProps {
  itemBackground: string;
  contentRef: Ref<HTMLDivElement>;
  carouselLength: number;
}

export const TopCarouselCoverItem = memo(
  ({ item, itemBackground, itemRef, onItemClick, contentRef, carouselLength }: TopCarouselCoverItemProps) => {
    const { screenName } = useSectionTrackingDataContext();

    return (
      <TopCarouselCoverItemWrapper screenName={screenName} item={item} itemRef={itemRef} onItemClick={onItemClick}>
        <div css={styles.carouselItemInnerStyle}>
          <TopCarouselBackground
            css={styles.carouselItemBackgroundStyle}
            backgroundUrl={itemBackground}
            gradientColor={item.contents.gradation_color}
            gradientCss={styles.carouselItemGradientStyle}
          />

          <TrackViewEvent ref={contentRef} screenName={screenName} target="top_carousel_cover" params={item.params}>
            <TopCarouselContent css={styles.carouselItemContentStyle}>
              <TopCarouselContentBadge css={styles.carouselItemBadgesStyle} badges={item.contents.badges} />
              <TopCarouselContentTitle css={styles.carouselItemTitleStyle}>
                {item.contents.title}
              </TopCarouselContentTitle>
              <div css={styles.carouselItemIndicatorWrapperStyle}>
                <div css={styles.carouselItemColumnStyle}>
                  <TopCarouselContentDescription css={styles.carouselItemDescriptionStyle}>
                    {item.contents.hooking_sentence}
                  </TopCarouselContentDescription>
                  <TopCarouselContentCopyrights copyrights={item.contents.copyrights} />
                </div>
                <TopCarouselContentIndicator index={item.index} length={carouselLength} />
              </div>
            </TopCarouselContent>
          </TrackViewEvent>
        </div>
      </TopCarouselCoverItemWrapper>
    );
  },
);
