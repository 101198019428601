import { ComponentProps, FunctionComponent, ReactNode, useMemo } from 'react';

import { TopCarouselBannerItem, TopCarouselCoverItem } from '@/models/backendsApi/v2/Views/ViewsType';

export interface Carousel {
  length: number;
  lengthFilled: number;
}

type TopCarouselItem = TopCarouselBannerItem | TopCarouselCoverItem;
export type CarouselItem<T extends TopCarouselItem> = T & {
  uniqueId: string;
  index: number;
};

export type TopCarouselInner<T extends TopCarouselItem> = FunctionComponent<{
  length: number;
  itemsFilled: CarouselItem<T>[];
}>;

export interface TopCarouselControllerProps<T extends TopCarouselItem> {
  children: (props: ComponentProps<TopCarouselInner<T>>) => ReactNode;
  items: T[];
  redundancy: number;
}

const MIN_ITEMS = 8;
export const TopCarouselController = <T extends TopCarouselItem>({
  items,
  redundancy = 2,
  children,
}: TopCarouselControllerProps<T>): ReactJSX.Element => {
  const itemsFilled = useMemo(() => {
    const redundantItems = items
      .slice(0, redundancy)
      .map((item, index) => ({ ...item, index, uniqueId: `additional_${item.id}` }));

    const repeatCount = items.length > 0 ? Math.ceil(MIN_ITEMS / items.length) : 0;
    return Array.from({ length: repeatCount })
      .flatMap((_, repeatIndex) =>
        items.map((item, index) => ({ ...item, index, uniqueId: `${repeatIndex}_${item.id}` })),
      )
      .concat(redundantItems);
  }, [redundancy, items]);

  const { length } = items;

  return <>{children({ itemsFilled, length })}</>;
};
