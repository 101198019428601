import { memo, MouseEvent, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';

import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { useAutomaticScroll } from '@/components/genreHome/common/TopCarousel/hooks';
import { TopCarouselArrow } from '@/components/genreHome/common/TopCarousel/TopCarouselArrow';
import {
  CarouselItem,
  TopCarouselController,
  TopCarouselInner,
} from '@/components/genreHome/common/TopCarousel/TopCarouselController';
import { calculateCarouselPosition } from '@/components/genreHome/common/TopCarousel/utils';
import { SwipeTweenContext, SwipeTweenTag, useSwipeWithTween } from '@/components/genreHome/hooks';
import { BreakPoint } from '@/components/styles/media';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { useArrayRef } from '@/hooks/useArrayRefs';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';
import { useLatestRef } from '@/hooks/useLatestRef';
import { useMount } from '@/hooks/useMount';
import { useResponsiveIsBelow } from '@/hooks/useResponsive';
import { circularTweenSpace } from '@/hooks/useTween';
import {
  SectionLayoutType,
  TopCarouselCoverItem as TopCarouselCoverItemType,
} from '@/models/backendsApi/v2/Views/ViewsType';
import { easeInOutQuint, easeOutCubic } from '@/utils/easing';
import { EventParamsType, sendScrollEvent } from '@/utils/eventClient';
import { debounce, throttle } from '@/utils/functions';
import { mergeRefs } from '@/utils/mergeRefs';

import { UNEXPANDED_BOX, UNEXPANDED_ITEM_WIDTH } from './TopCarouselCover.styles';
import * as styles from './TopCarouselCover.styles';
import { TopCarouselCoverItem, TopCarouselCoverItemSkeleton } from './TopCarouselCoverItem';

const clamp = (min: number, value: number, max: number) => Math.min(max, Math.max(min, value));

type ItemVisibilityFlags = { isHidden: boolean; isExpanded: boolean };
const calculateFlags = (position: number): ItemVisibilityFlags => ({
  isHidden: position < 0 || position > 2,
  isExpanded: position >= 0 && position < 1,
});

const calculateStyle = (position: number): { grow: number; basis: number } => {
  const grow = 1 - Math.abs(clamp(-1, position, 1));
  const basisBelow = UNEXPANDED_ITEM_WIDTH * clamp(0, position, 1);
  const basisOver = UNEXPANDED_ITEM_WIDTH * (3 - clamp(2, position, 3));

  return { grow, basis: Math.min(basisBelow, basisOver) };
};

const CONTENT_LEAVE_OFFSET = 32;
const calculateContentStyle = (position: number): { offset: number; opacity: number } => ({
  offset: clamp(-1, position, 0) * CONTENT_LEAVE_OFFSET,
  opacity: 1 - Math.abs(clamp(-1, position, 1)),
});

const REDUNDANT_ITEMS = 3;
const DEFAULT_VISIBLE_ITEMS = 3;

const useTweenAnimation = () => {
  const lastTweenAnimationRef = useRef(0);
  const tweenAnimation = useCallback((velocity: number, tag: SwipeTweenTag) => {
    const now = Date.now();
    const lastTweenAnimation = lastTweenAnimationRef.current;
    lastTweenAnimationRef.current = now;

    const timeDelta = now - lastTweenAnimation;
    const speed = Math.abs(velocity);
    if (timeDelta < 50) {
      return { easing: easeOutCubic, duration: 180 };
    }

    if (tag.name === 'snap' || timeDelta < 400) {
      return { easing: easeOutCubic, duration: Math.max(180, 560 * (1 - speed * 0.5)) };
    }

    return { easing: easeInOutQuint, duration: 1000 };
  }, []);

  return tweenAnimation;
};

export const tests__TopCarouselCover = { calculateFlags, calculateStyle, calculateContentStyle, useTweenAnimation };

export type TopCarouselCoverRenderItem = CarouselItem<TopCarouselCoverItemType> & {
  initialPosition: number;
  initialFlags: ItemVisibilityFlags;
  params: EventParamsType;
};

type TopCarouselCoverInnerType = TopCarouselInner<TopCarouselCoverItemType>;
export const TopCarouselCoverInner: TopCarouselCoverInnerType = memo(({ itemsFilled, length }) => {
  const eventParams = useSectionTrackingDataContext();
  const lengthFilled = itemsFilled.length;

  /*
   * Element References
   */
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { itemRefs: contentRefs, itemRef: contentRef } = useArrayRef<HTMLDivElement>();
  const { itemRefs, itemRef } = useArrayRef<HTMLAnchorElement>();

  /*
   * Variable References
   */
  const activeItemWidthRef = useRef<number>(BreakPoint.DesktopDefault);
  const updateIsSwipingRef = useRef((_isSwiping: boolean) => {});
  const updateIsSwiping = useCallback((isSwiping: boolean) => updateIsSwipingRef.current(isSwiping), []);

  /*
   * Event Handling
   */
  const eventParamsRef = useLatestRef(eventParams);
  const handleScrollEvent = useMemo(
    () =>
      debounce(() => {
        if (eventParamsRef.current) {
          sendScrollEvent(eventParamsRef.current.screenName, 'section', eventParamsRef.current.params);
        }
      }, 500),
    [eventParamsRef],
  );

  /*
   * Default Carousel Behaviour
   * > 리렌더가 터치 반응성의 하락을 불러오는 이슈로 인해,
   *   최대한 State 사용 없이 Imperative하게 작성
   * > Items는 세심한 조작을 필요로 하기 때문에 CSS Transition 만으로는 짜기 어려움
   *   Grow의 합은 1이라는 Invariant를 지키면서 Transition하기 위해 직접 Tween 구현
   * > Contents는 CSS Transition 만으로도 별 다른 이슈가 없기 때문에 따로 애니메이팅
   */
  const updateTabIndexRef = useRef(() => {});
  const updateAllItems = useCallback(
    (carouselIndex: number) => {
      updateTabIndexRef.current();
      itemRefs.current.forEach((item, index) => {
        if (!item) {
          return;
        }

        const position = index - carouselIndex;
        const style = calculateStyle(position);
        item.style.setProperty('flex-grow', `${style.grow}`);
        item.style.setProperty('flex-basis', `${style.basis}px`);

        const content = contentRefs.current[index];
        if (!content) {
          return;
        }

        const contentStyle = calculateContentStyle(position);
        content.style.setProperty('transform', `translate(${contentStyle.offset}px, 0)`);
        content.style.setProperty('opacity', `${contentStyle.opacity}`);
      });
    },
    [contentRefs, itemRefs],
  );

  const updateItemWidths = useCallback(() => {
    contentRefs.current.forEach(content => {
      if (content) {
        content.style.setProperty('width', `${activeItemWidthRef.current}px`);
      }
    });
  }, [contentRefs]);
  useIsomorphicLayoutEffect(() => updateItemWidths());

  const updateItemWithEvent = useCallback(
    (index: number, context: SwipeTweenContext) => {
      if (context.tag.name === 'swipe') {
        handleScrollEvent();
      }
      updateAllItems(index);
    },
    [handleScrollEvent, updateAllItems],
  );

  /*
   * Tweening & Swipe Handling
   * > 스와이프 시에는 이미 속도가 있으므로 진출만 easing 해준다
   * > 연타할 때는 진입 시의 easing이 너무 가파르지 않게 하고, 빠르게 보여준다
   * > 일반적인 케이스에는 진입과 진출 모두 easing 해준다
   */
  const tweenAnimation = useTweenAnimation();
  const tweenSpace = useMemo(() => circularTweenSpace(lengthFilled - REDUNDANT_ITEMS), [lengthFilled]);

  const maxSwipeItemRef = useRef(1);
  const { swipeRef, indexRef, updateIndex } = useSwipeWithTween(
    { updateItem: updateItemWithEvent, updateIsSwiping },
    { itemWidth: activeItemWidthRef, maxSwipeItem: maxSwipeItemRef },
    { animation: tweenAnimation, space: tweenSpace },
  );

  /*
   * Resize Handling
   */
  useEffect(() => {
    const onResize = () => {
      if (wrapperRef.current) {
        activeItemWidthRef.current = wrapperRef.current.clientWidth - UNEXPANDED_BOX;
      }
      updateItemWidths();
      updateIndex();
    };
    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [updateAllItems, updateItemWidths, updateIndex]);

  /*
   * Auto Scrolling
   */
  const isFocusedRef = useRef(false);
  const isSwipingRef = useRef(false);
  const { onToggle } = useAutomaticScroll(() => updateIndex(indexRef.current + 1));

  const updateAutomaticScroll = useCallback(() => {
    onToggle(!isFocusedRef.current && !isSwipingRef.current);
  }, [onToggle]);

  updateIsSwipingRef.current = isSwiping => {
    isSwipingRef.current = isSwiping;
    updateAutomaticScroll();
  };

  const onBlur = useCallback(() => {
    isFocusedRef.current = true;
    updateAutomaticScroll();
  }, [updateAutomaticScroll]);

  const onFocus = useCallback(() => {
    isFocusedRef.current = false;
    updateAutomaticScroll();
  }, [updateAutomaticScroll]);

  /*
   * Other Behaviours
   */
  const [, startTransition] = useTransition();
  const [isHydrated, setIsHydrated] = useState(false);
  useMount(() => {
    startTransition(() => setIsHydrated(true));
  });

  const mergedWrapperRef = useMemo(() => mergeRefs([wrapperRef, swipeRef]), [swipeRef]);

  const throttledUpdateIndex = useMemo(
    () => throttle((nextIndex?: number) => updateIndex(nextIndex), 200, true),
    [updateIndex],
  );

  const createOnItemClick = (indexFilled: number) => (event: MouseEvent) => {
    const isExpanded = indexRef.current === indexFilled;
    if (!isExpanded) {
      event.preventDefault();
      throttledUpdateIndex(indexFilled);
    }
  };

  updateTabIndexRef.current = useMemo(
    () =>
      debounce(() => {
        itemRefs.current.forEach((item, index) => {
          if (!item) {
            return;
          }

          /* eslint-disable-next-line no-param-reassign */
          item.tabIndex = index === indexRef.current ? 0 : -1;
        });
      }, 500),
    [itemRefs, indexRef],
  );

  /*
   * Rendering
   */
  const renderingItems = useMemo<TopCarouselCoverRenderItem[]>(
    () =>
      itemsFilled
        .map((item, index) => ({
          ...item,
          initialPosition: calculateCarouselPosition(index, 0, lengthFilled),
        }))
        .map(item => ({
          ...item,
          initialFlags: calculateFlags(item.initialPosition),
        }))
        .map(item => ({
          ...item,
          params: {
            ...eventParams.params,
            item_id: item.id,
            item_title: item.contents.title,
            item_index: item.index,
            item_landing_url: item.contents.landing_url,
          },
        })),
    [eventParams.params, itemsFilled, lengthFilled],
  );

  const isMobile = useResponsiveIsBelow(BreakPoint.DesktopSmall, false);
  const backgroundImagesMap = useMemo(
    () =>
      itemsFilled.reduce<Record<number, string>>(
        (imageMap, item) => ({
          ...imageMap,
          [item.id]: isMobile ? item.contents.mobile_main_image_url : item.contents.pc_main_image_url,
        }),
        {},
      ),
    [itemsFilled, isMobile],
  );

  return (
    <TrackViewEvent screenName={eventParams.screenName} target="section" params={eventParams.params}>
      <section css={styles.carouselWrapperStyle}>
        <div
          css={styles.carouselStyle}
          ref={mergedWrapperRef}
          onBlur={onBlur}
          onFocus={onFocus}
          data-is-hydrated={isHydrated}>
          <div css={styles.carouselItemsStyle}>
            {renderingItems.map((item, index) =>
              isHydrated || index < DEFAULT_VISIBLE_ITEMS ? (
                <TopCarouselCoverItem
                  key={item.uniqueId}
                  item={item}
                  itemBackground={backgroundImagesMap[item.id]}
                  itemRef={itemRef(index)}
                  onItemClick={createOnItemClick(index)}
                  carouselLength={length}
                  contentRef={contentRef(index)}
                />
              ) : (
                <TopCarouselCoverItemSkeleton
                  key={item.uniqueId}
                  item={item}
                  itemRef={itemRef(index)}
                  onItemClick={createOnItemClick(index)}
                />
              ),
            )}
          </div>
          <div css={styles.carouselChildrenStyle}>
            <TopCarouselArrow
              onClickPrev={() => updateIndex(indexRef.current - 1)}
              onClickNext={() => updateIndex(indexRef.current + 1)}
            />
          </div>
        </div>
      </section>
    </TrackViewEvent>
  );
});

export interface TopCarouselCoverProps {
  section: SectionByLayout<SectionLayoutType.TopCarouselCover>;
}

export const TopCarouselCover = memo(
  ({ section }: TopCarouselCoverProps): ReactJSX.Element => (
    <TopCarouselController items={section.items} redundancy={REDUNDANT_ITEMS}>
      {props => <TopCarouselCoverInner {...props} />}
    </TopCarouselController>
  ),
);
