import { useCallback, useEffect, useRef } from 'react';

const SCROLL_DURATION = 5000;

export const useAutomaticScroll = (onScroll: () => void): { onToggle: (isEnabled: boolean) => void } => {
  const timeoutHandleRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const onScrollRef = useRef(onScroll);
  onScrollRef.current = onScroll;

  const autoScrollHandler = useCallback(() => {
    onScrollRef.current();
    timeoutHandleRef.current = setTimeout(autoScrollHandler, SCROLL_DURATION);
  }, []);

  const onToggle = useCallback(
    (isEnabled: boolean) => {
      if (timeoutHandleRef.current !== null) {
        window.clearTimeout(timeoutHandleRef.current);
      }

      if (isEnabled) {
        timeoutHandleRef.current = setTimeout(autoScrollHandler, SCROLL_DURATION);
      } else {
        timeoutHandleRef.current = null;
      }
    },
    [autoScrollHandler],
  );

  useEffect(() => {
    onToggle(true);

    return () => onToggle(false);
  }, [onToggle]);

  return { onToggle };
};
