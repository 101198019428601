import { css } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { BreakPoint, greaterThan, orBelow } from '@/components/styles/media';

import { sectionSkeletonBaseStyle } from '../index.styles';
import { ITEM_MARGIN, UNEXPANDED_BOX, UNEXPANDED_ITEM_WIDTH } from './TopCarouselCover.styles';

export const carouselItemStyle = css`
  width: 0;
  height: 400px;

  [data-is-hydrated='false'] & {
    flex-grow: 0;
    flex-basis: ${UNEXPANDED_ITEM_WIDTH}px;

    &[data-hydrate-expanded='true'] {
      flex-grow: 1;
    }

    &[data-hydrate-hidden='true'] {
      flex-basis: 0;
    }
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 343px;
    `,
  )};
`;

export const carouselItemInnerStyle = css`
  position: relative;
  border-radius: 6px;
  margin: 0 3px;
  overflow: hidden;
  height: 100%;
`;

export const carouselItemInnerSkeletonStyle = sectionSkeletonBaseStyle;

export const carouselItemContentStyle = css`
  width: 100vw;
  padding: 40px;
  padding-top: 0;
  padding-right: ${40 + ITEM_MARGIN}px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 16px;
      padding-top: 0;
      padding-right: ${16 + ITEM_MARGIN}px;
      padding-bottom: 22px;
    `,
  )};
`;

export const carouselItemBackgroundStyle = css`
  width: ${1110 - UNEXPANDED_BOX}px;
  height: 400px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 735px;
      height: 343px;
    `,
  )};
`;

export const carouselItemGradientStyle = css`
  height: 200px;
`;

export const carouselItemMaxWidthStyle = css`
  max-width: calc(85% + 40px);

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      max-width: calc(85% + 16px);
    `,
  )};
`;

export const carouselItemMaxWidthFlexStyle = css`
  flex-basis: calc(85% + 40px);

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      flex-basis: calc(85% + 16px);
    `,
  )};
`;

export const carouselItemIndicatorWrapperStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

export const carouselItemColumnStyle = css`
  ${carouselItemMaxWidthStyle};
  ${carouselItemMaxWidthFlexStyle};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const carouselItemTitleStyle = css`
  ${carouselItemMaxWidthStyle};

  font-size: 24px;
  ${lineClamp(2, '30px')};
  white-space: pre-line;

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      font-size: 32px;
      ${lineClamp(2, '40px')};
      white-space: pre-line;
    `,
  )};
`;

export const carouselItemDescriptionStyle = css`
  ${carouselItemMaxWidthStyle};

  ${greaterThan(
    BreakPoint.DesktopSmall,
    css`
      font-size: 16px;
      ${lineClamp(2, '19px')};
      white-space: pre-line;
    `,
  )};
`;

export const carouselItemBadgesStyle = css`
  margin-bottom: 8px;
`;
