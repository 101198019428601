import { css, SerializedStyles } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';
import { rgba } from '@/utils/rgba';

export const contentStyle = css`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
`;

export const titleStyle = ({ theme }: { theme: RIDITheme }): SerializedStyles => css`
  ${lineClamp(2, '32px')};

  overflow-wrap: break-word;
  white-space: pre-line;
  color: ${theme.colors.white};
  font-size: 26px;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin-bottom: 4px;
  width: 100%;
`;

export const descriptionStyle = ({ theme }: { theme: RIDITheme }): SerializedStyles => css`
  ${lineClamp(2, '16px')};

  overflow-wrap: break-word;
  white-space: pre-line;
  color: ${theme.colors.white};
  font-size: 14px;
  letter-spacing: -0.01em;
  opacity: 0.8;
  width: 100%;
`;

export const copyrightsStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 8px;
  color: ${theme.colors.white};
  opacity: 0.4;
  letter-spacing: -0.01em;
  ${lineClamp(3, '11px')};

  word-break: break-all;
  margin-top: 8px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 7px;
      ${lineClamp(3, '9px')};

      word-break: break-all;
    `,
  )};
`;

export const indicatorStyle = (theme: RIDITheme): SerializedStyles => css`
  position: relative;

  background: ${rgba(theme.colors.black, 0.1)};
  flex: 0 0 auto;
  border-radius: 100px;
  padding: 3px 8px;
  padding-top: 2px;

  display: flex;
  align-items: center;
  color: ${theme.colors.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 3px 8px;
      font-size: 10px;
      line-height: 12px;
    `,
  )};
`;

export const indicatorActiveStyle = css`
  display: inline-block;
`;

export const indicatorInactiveStyle = css`
  ${indicatorActiveStyle};
  opacity: 0.4;
`;

export const indicatorDividerStyle = css`
  width: 1px;
  height: 9px;
  margin: 0 3px;
  background: currentColor;
  border-radius: 1px;
  transform: rotate(15deg);
  opacity: 0.4;
`;

export const badgesStyle = (theme: RIDITheme): SerializedStyles => css`
  display: inline-flex;
  border: 1px solid ${theme.colors.white};
  border-radius: 2px;
  margin-bottom: 6px;
`;

export const badgeStyle = (theme: RIDITheme): SerializedStyles => css`
  width: auto;
  height: 20px;
  border-right: 1px solid ${theme.colors.white};

  &:last-of-type {
    border-right: none;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      height: 16px;
    `,
  )};
`;
