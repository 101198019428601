import { SerializedStyles } from '@emotion/react';
import { useMemo } from 'react';

import { rgba } from '@/utils/rgba';

import * as styles from './TopCarouselBackground.styles';

interface TopCarouselBackgroundProps {
  backgroundUrl: string;
  gradientColor: string | null;
  className?: string;
  gradientCss?: SerializedStyles;
}

export const TopCarouselBackground = ({
  className,
  backgroundUrl,
  gradientColor,
  gradientCss,
}: TopCarouselBackgroundProps): ReactJSX.Element => {
  const backgroundImageStyle = useMemo(
    () => ({
      backgroundImage: `url(${backgroundUrl})`,
    }),
    [backgroundUrl],
  );

  const gradientStyle = useMemo(() => {
    if (!gradientColor) {
      return {};
    }

    const gradientStart = rgba(gradientColor, 0);
    const gradientEnd = rgba(gradientColor, 1);

    return {
      backgroundImage:
        `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd}),` +
        `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd})`,
    };
  }, [gradientColor]);

  return (
    <div css={styles.backgroundStyle} className={className} style={backgroundImageStyle}>
      <div css={[styles.gradientStyle, gradientCss]} style={gradientStyle} />
    </div>
  );
};
