import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const backgroundStyle = (theme: RIDITheme): SerializedStyles => css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: ${theme.colors.grey200};
  background-size: cover;
  background-position: center;
`;

export const gradientStyle = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-size: 100% calc(100% + 2px);
  background-position: center;
`;
