import { css } from '@emotion/react';

import { displayNoneForTouchDevice } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';

export const buttonStyle = css`
  position: absolute;
  top: 50%;
  pointer-events: auto;
  ${displayNoneForTouchDevice};
`;

export const prevButtonStyle = css`
  ${buttonStyle};
  left: 0;
  transform: translate(-50%, -50%);

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      left: -16px;
      transform: none;
    `,
  )};
`;

export const nextButtonStyle = css`
  ${buttonStyle};
  right: 0;
  transform: translate(50%, -50%);

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      right: -16px;
      transform: none;
    `,
  )};
`;
