import { MutableRefObject, Ref, useRef } from 'react';

type UseArrayRef<T> = {
  itemRefs: MutableRefObject<(T | void)[]>;
  itemRef: (index: number) => Ref<T>;
};

export const useArrayRef = <T extends HTMLElement>(): UseArrayRef<T> => {
  const itemCallbackRefs = useRef<((element: T) => void)[]>([]);
  const itemRefs = useRef<(T | void)[]>([]);
  const itemRef = (index: number) => {
    if (!itemCallbackRefs.current[index]) {
      itemCallbackRefs.current[index] = (element: T) => {
        itemRefs.current[index] = element;
      };
    }

    return itemCallbackRefs.current[index];
  };

  return { itemRefs, itemRef };
};
